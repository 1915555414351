.bg {
  background-image: url("assets/background.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.marginLeftCLASS {
  margin-left: 20px;
}
.man {
  width: 84%;
}
.onetext {
  font-size: 4vw;
}
.twotext {
  font-size: 4vw;
}
.buttonhome {
  width: 20vw !important;
}
.text3 {
  font-size: 1.5vw;
}
.bottomtext {
  font-size: 1vw;
}

@media screen and (max-width: 768px) {
  .man {
    width: 99%;
  }
  .text3 {
    font-size: 2vw;
  }
}
@media screen and (max-width: 637px) and (min-width: 575px) {
  .man {
    width: 100%;
  }
  .onetext {
    font-size: 3vw;
  }
  .twotext {
    font-size: 3vw;
  }
}
@media screen and (max-width: 576px) {
  .man {
    display: none;
  }
}
@media screen and (max-width: 575px) {
  .onetext {
    font-size: 5vw;
  }
  .twotext {
    font-size: 5vw;
  }
  .buttonhome {
    width: 30vw !important;
  }
  .text3 {
    font-size: 3vw;
  }
  .bottomtext {
    font-size: 2vw;
  }
}
@media screen and (max-width: 420px) {
  .buttonhome {
    width: 40vw !important;
  }
}
@media screen and (max-width: 320px) {
  .buttonhome {
    width: 42vw !important;
  }
  .marginLeftCLASS {
    margin-left: 2px;
  }
}
