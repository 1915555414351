body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "poppins";
  src: local("poppins"), url(./fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "poppinsbold";
  src: local("poppins"), url(./fonts/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "zendot";
  src: local("poppins"), url(./fonts/ZenDots-Regular.ttf) format("truetype");
}
